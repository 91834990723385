@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply dark:bg-gray-1000;
}
.auth-bg {
  height: 100vh;
  width: 100%;
  /* Set relative position to allow placement of pseudo-elements */
  position: fixed;
  overflow: hidden; /* Hide overflowing content of pseudo-elements */

  /* Add background image (replace 'your-image.jpg' with the path to your image) */
  background-image: url('/images/auth-bg.png'), url('/images/auth-bg-2.png');

  /* Set the background image position to the bottom right corner */
  background-position: bottom right, top left;

  /* Prevent the background image from repeating */
  background-repeat: no-repeat;
}

.auth-bg::before,
.auth-bg::after {
  /* Create pseudo-elements as children of the body */
  content: '';

  /* Set absolute position to position the pseudo-elements relative to the body */
  position: absolute;

  /* Set the size of the pseudo-elements */
  width: 700px;
  height: 700px;

  /* Radial gradient starts with color #E1EFFE80 and ends with color #FFFFFF */
  background-image: radial-gradient(circle at bottom left, #e1effe80, #ffffff);

  /* Apply a blur effect of 100px */
  filter: blur(100px);

  /* Set the z-index to place the pseudo-elements below the content */
  z-index: -1;
}

.auth-bg::before {
  /* Position the first pseudo-element in the bottom left corner */
  bottom: 0;
  left: 0;
}

.auth-bg::after {
  /* Position the second pseudo-element in the top right corner */
  top: 0;
  right: 0;

  /* Change the position of the radial gradient in the second pseudo-element */
  background-image: radial-gradient(circle at top right, #e1effe80, #ffffff);
}

.text-link {
  @apply cursor-pointer text-xs text-blue-600 hover:underline dark:text-blue-500;
}

::-webkit-scrollbar {
}

/* Custom Lebra Transitions */
@keyframes fadeInSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeInSlideDown 0.5s ease-in-out forwards;
}

/* Customer Lebra Gradients (home-page) */
.c-bg {
  background: rgb(213, 223, 255);
  background: -moz-radial-gradient(
    circle,
    rgba(213, 223, 255, 0.3) 0%,
    rgba(247, 249, 255, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(213, 223, 255, 0.3) 0%,
    rgba(247, 249, 255, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(213, 223, 255, 0.3) 0%,
    rgba(247, 249, 255, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d5dfff",endColorstr="#ffffff",GradientType=1);
}

.c-bg-2 {
  background: radial-gradient(
      farthest-side at bottom left,
      rgba(213, 223, 255, 0.3),
      transparent
    ),
    radial-gradient(
      farthest-corner at top right,
      rgba(213, 223, 255, 0.3),
      transparent 400px
    );
}
.c-bg-3 {
  background: radial-gradient(
    circle closest-corner at top,
    rgba(213, 223, 255, 0.2),
    transparent
  );
}

/* Scrollbar hiding */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

a {
  @apply text-blue-600 hover:text-blue-700 transition-colors duration-200;
}

/* Blue gradient used for sign in pages */
.rounded-gradient {
  border-radius: 12px;
  box-shadow: 0 0 75px 75px rgba(28, 100, 242, 0.05);
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.unreset {
  & p {
    margin: 1em 0;
  }

  & ul {
    list-style-type: disc;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }

  & ol {
    list-style-type: decimal;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }

  & hr {
    border: 1px inset;
    box-sizing: border-box;
    margin: 0.5em auto;
  }

  & h1 {
    font-size: 2em;
    font-weight: bold;
    margin: 0.67em 0;
  }

  & h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.83em 0;
  }

  & h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin: 1em 0;
  }

  & h4 {
    font-size: 1em;
    font-weight: bold;
    margin: 1.33em 0;
  }

  & h5 {
    font-size: 0.83em;
    font-weight: bold;
    margin: 1.67em 0;
  }

  & h6 {
    font-size: 0.67em;
    font-weight: bold;
    margin: 2.33em 0;
  }

  & table {
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
  }

  & thead,
  & tbody,
  & tfoot,
  & tr {
    border-color: inherit;
    vertical-align: middle;
  }

  & td,
  & th {
    padding: 1px;
    vertical-align: inherit;
  }

  & th {
    font-weight: bold;
  }

  & pre {
    font-family: monospace;
    margin: 1em 0;
    white-space: pre;
  }

  & iframe {
    border: 2px inset;
  }
}


/* shortcut keys */

/* Used on default outline buttons */

.shortcut-key {
  @apply bg-blue-50 border border-blue-100 text-blue-500 px-2 py-1 rounded text-sm;
}

/* Used on a blue button */

.shortcut-key-blue {
  @apply border border-blue-600 text-gray-100 px-2 py-0.5 rounded text-sm bg-blue-500;
}


/* Used for the lebra assistant */
.assistant-input {
  background-image: linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#fff 54.73%)
}
.assistant-message-content p,
.assistant-message-content ul,
.assistant-message-content ol,
.assistant-message-content hr {
  margin-bottom: 12px;
}

.assistant-message-content ol {
  list-style: decimal;
  padding-left: 32px;
}
.assistant-message-content ul {
  list-style: disc;
  padding-left: 32px;
}
/* Bold h1,h2,h3,h4 of .assistant-message-content */
.assistant-message-content h1,
.assistant-message-content h2,
.assistant-message-content h3,
.assistant-message-content h4 {
  font-weight: 600;
}